import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  private subject = new Subject<any>();
  private subjectUserInfo = new Subject<boolean>();

  changeTimezone(timezone: string) {
    this.subject.next({ timezone: timezone });
  }

  getNewTimezone(): Observable<any> {
    return this.subject.asObservable();
  }

  changeUserInfo(flag: boolean) {
    this.subjectUserInfo.next(flag);
  }

  getUserInfo(): Observable<boolean> {
    return this.subjectUserInfo.asObservable();
  }
}
